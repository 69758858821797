import GameGrid from './GameGrid'
//import Modal from './Modal'
import './Luminoes.css';

export default function GameContainer() {

    return (
        <div className='inner-container'>
            <GameGrid /> 
            {/* <Modal /> */}
        </div>

  );
}

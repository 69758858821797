import './Luminoes.css';

function Square({isOn, onClick, gridState, affectedAddrs, affectedOns, origin}) {

   let className = 'circle'
   let dotClasses = affectedOns.map(on => !on ? className + ' n' : className + ' f')
   dotClasses[origin] = className + ' o'
    
    return (
        // Container for the circle grid and the square holding current state
        <div className={'tmp-square ' + (isOn ? 'n' : 'f')} onClick={onClick}>
            {gridState.map((_,index) => (
                <div className={affectedAddrs.includes(index) ? dotClasses.shift() : className} key ={index}></div>
            ))}
        </div>
  );
}

export default Square;

import './Luminoes.css';
import {useEffect, useState} from 'react';

export default function Title() {

    const title = 'LUMiNOES'.split('')

    const [classes, setClasses] = useState([])

    useEffect(() => {
        const randClasses = Array.from({length: title.length}, () => Math.random() > 0.5 ? 'title n' : 'title f');
        randClasses[3] = 'title i'
        setClasses(randClasses)
    },[])

    return (
        // Container for the circle grid and the square holding current state
        <div className='title-container'>
            {title.map((letter,index) => (
                <span className={classes[index]} key={'title'+index}>{letter}</span>
            )
            )}
        </div>
    );
}
import './Luminoes.css';
import Square from './Square';
import Title from './Title'
import { useState, useEffect } from 'react';

export default function GameGrid() {

    // Array of cells
    // Each sub index is an array of legal pieces and their orientations-origins
    const legalPositions = [
        ['O00', 'I00', 'I10', 'S10', 'Z00', 'L00', 'L20', 'L30', 'J10', 'J20', 'J30', 'T00', 'T10'],
        ['O00', 'O01', 'I00', 'I11', 'S00', 'S10', 'Z00', 'Z01', 'Z10', 'L00', 'L20', 'L21', 'L30', 'L31', 'J00', 'J10', 'J11', 'J20', 'J21', 'J30', 'T00', 'T01', 'T10', 'T20', 'T30'],
        ['O00', 'O01', 'I00', 'I12', 'S00', 'S01', 'S10', 'Z01', 'Z10', 'L00', 'L10', 'L20', 'L21', 'L31', 'L32', 'J00', 'J11', 'J12', 'J20', 'J21', 'T01', 'T02', 'T10', 'T20', 'T30'],
        ['O01', 'I00', 'I13', 'S01', 'Z10', 'L10', 'L21', 'L32', 'J00', 'J12', 'J21', 'T02', 'T30'],
        ['O00', 'O02', 'I01', 'I10', 'S02', 'S10', 'S11', 'Z00', 'Z11', 'L00', 'L01', 'L11', 'L20', 'L30', 'L33', 'J10', 'J20', 'J30', 'J31', 'T00', 'T10', 'T11', 'T21', 'T31'],
        ['O00', 'O01', 'O02', 'O03', 'I01', 'I11', 'S00', 'S02', 'S03', 'S10', 'S11', 'S12', 'Z00', 'Z01', 'Z02', 'Z10', 'Z11', 'Z12', 'L00', 'L01', 'L11', 'L12', 'L20', 'L21', 'L22', 'L30', 'L31', 'L33', 'J00', 'J01', 'J10', 'J11', 'J20', 'J21', 'J22', 'J30', 'J31', 'J32', 'T00', 'T01', 'T03', 'T10', 'T11', 'T12', 'T20', 'T21', 'T22', 'T30', 'T31', 'T32'],
        ['O00', 'O01', 'O02', 'O03', 'I01', 'I12', 'S00', 'S01', 'S03', 'S10', 'S11', 'S12', 'Z01', 'Z02', 'Z03', 'Z10', 'Z11', 'Z12', 'L00', 'L01', 'L10', 'L12', 'L13', 'L20', 'L21', 'L22', 'L31', 'L32', 'J00', 'J01', 'J11', 'J12', 'J13', 'J20', 'J21', 'J22', 'J32', 'J33', 'T01', 'T02', 'T03', 'T10', 'T11', 'T12', 'T20', 'T22', 'T23', 'T30', 'T31', 'T32'],
        ['O01', 'O03', 'I01', 'I13', 'S01', 'S12', 'Z03', 'Z10', 'Z12', 'L10', 'L13', 'L21', 'L22', 'L32', 'J00', 'J01', 'J12', 'J13', 'J21', 'J22', 'J33', 'T02', 'T12', 'T23', 'T30', 'T32'],
        ['O00', 'O02', 'I02', 'I10', 'S02', 'S11', 'Z00', 'Z11', 'Z13', 'L01', 'L02', 'L11', 'L30', 'L33', 'J02', 'J10', 'J30', 'J31', 'T00', 'T11', 'T13', 'T21', 'T31'],
        ['O00', 'O01', 'O02', 'O03', 'I02', 'I11', 'S00', 'S02', 'S03', 'S11', 'S12', 'S13', 'Z00', 'Z01', 'Z02', 'Z11', 'Z12', 'Z13', 'L01', 'L02', 'L03', 'L11', 'L12', 'L22', 'L23', 'L30', 'L31', 'L33', 'J01', 'J02', 'J03', 'J10', 'J11', 'J22', 'J23', 'J30', 'J31', 'J32', 'T00', 'T01', 'T03', 'T11', 'T12', 'T13', 'T20', 'T21', 'T22', 'T31', 'T32', 'T33'],
        ['O00', 'O01', 'O02', 'O03', 'I02', 'I12', 'S00', 'S01', 'S03', 'S11', 'S12', 'S13', 'Z01', 'Z02', 'Z03', 'Z11', 'Z12', 'Z13', 'L01', 'L02', 'L03', 'L10', 'L12', 'L13', 'L22', 'L23', 'L31', 'L32', 'J01', 'J02', 'J03', 'J11', 'J12', 'J13', 'J22', 'J23', 'J32', 'J33', 'T01', 'T02', 'T03', 'T11', 'T12', 'T13', 'T20', 'T22', 'T23', 'T31', 'T32', 'T33'],
        ['O01', 'O03', 'I02', 'I13', 'S01', 'S12', 'S13', 'Z03', 'Z12', 'L03', 'L10', 'L13', 'L22', 'L23', 'L32', 'J01', 'J03', 'J12', 'J13', 'J22', 'J23', 'J33', 'T02', 'T12', 'T23', 'T32', 'T33'],
        ['O02', 'I03', 'I10', 'S02', 'Z13', 'L02', 'L11', 'L33', 'J02', 'J31', 'T13', 'T21'],
        ['O02', 'O03', 'I03', 'I11', 'S02', 'S03', 'S13', 'Z02', 'Z13', 'L02', 'L03', 'L11', 'L12', 'L23', 'L33', 'J02', 'J03', 'J23', 'J31', 'J32', 'T03', 'T13', 'T21', 'T22', 'T33'],
        ['O02', 'O03', 'I03', 'I12', 'S03', 'S13', 'Z02', 'Z03', 'Z13', 'L02', 'L03', 'L12', 'L13', 'L23', 'J02', 'J03', 'J13', 'J23', 'J32', 'J33', 'T03', 'T13', 'T22', 'T23', 'T33'],
        ['O03', 'I03', 'I13', 'S13', 'Z03', 'L03', 'L13', 'L23', 'J03', 'J13', 'J23', 'J33', 'T23', 'T33'],
        ]

    // Creates a new board by selecting random, legal pieces
    const generateBoard = () => {
        let newBoard = []
        for (let ix = 0; ix < 16; ix++) {
            let slot = legalPositions[ix]
            newBoard.push(slot[Math.floor(Math.random()*slot.length)])
        }
        return newBoard
    }

    // Flip a coin for each cell until at most 4 are getting toggled
    const monkeyMix = () => {
        // Generate an array of 1 and 0 where the sum of all numbers >= 3
        let toggleArray = []
        while (toggleArray.reduce((s,i) => s+i,0)  < 3) {
          toggleArray = []
          for(let i = 0; i < 16; i++){
            toggleArray.push(Math.floor(Math.random()*2))
          }
        }
        
        return toggleArray.map((status, idx) => status === 1)
    }

    const [clicks, setClicks] = useState(0) // Number of clicks player has made
    const [squareStates,setSquareStates] = useState(Array(16).fill(true)) // [on/off] of each cell, starting solved and going backwards ensures each board is solvable
    const [hasWon,setHasWon] = useState(false) // Has the player won? (useEffect on squareStates)
    const [board,setBoard] = useState(generateBoard()) // Array of legal piece keys as defined by `legal` and used by `shapes`
    const [initialBoard,setInitialBoard] = useState(squareStates) // The inital state of the board for use by the reset game button 
    const [prevSquare,setPrevSquare] = useState([]) // Previously clicked square, for a potential undo feature later on

    /*
        [shape letter][rotation] : { 
            x: x offset from 0,0 reading left-to-right, up-to-down
            y: y offset from 0,0 reading left-to-right, up-to-down
        }

        Shape Letters: https://en.wikipedia.org/wiki/Tetromino
        Rotation: 0th rotation matches the above link's depiction 
        ---------> Except for I, which is sideway on the Wiki for some reason, I here is vertical at 0 rotations
        offsets: 
            for O0 (O shape, 0th rotation (although there are no rotations for O but whatever))
            0,0 is always the toppest then leftest cell (so J and S work)
            [0,0][1,0][   ]
            [0,1][1,1][   ]
            [   ][   ][   ]
    */
    const commandShapes = {
        'O0': {
                'x': [0, 1, 0, 1],
                'y': [0, 0, 1, 1]
            },
        'I1': {
                'x': [0, 1, 2, 3],
                'y': [0, 0, 0, 0]
            },
        'I0': {
                'x': [0, 0, 0, 0],
                'y': [0, 1, 2, 3]
            },
        'S0': {
                'x': [0, 1, -1, 0],
                'y': [0, 0, 1, 1]
            },
        'S1': {
                'x': [0, 0, 1, 1],
                'y': [0, 1, 1, 2]
            },
        'Z0': {
                'x': [0, 1, 1, 2],
                'y': [0, 0, 1, 1]
            },
        'Z1': {
                'x': [0, -1, 0, -1],
                'y': [0, 1, 1, 2]
            },
        'L0': {
                'x': [0, 0, 0, 1],
                'y': [0, 1, 2, 2]
            },
        'L1': {
                'x': [0, -2, -1, 0],
                'y': [0, 1, 1, 1]
            },
        'L2': {
                'x': [0, 1, 1, 1],
                'y': [0, 0, 1, 2]
            },
        'L3': {
                'x': [0, 1, 2, 0],
                'y': [0, 0, 0, 1]
            },
        'J0': {
                'x': [0, 0, -1, 0],
                'y': [0, 1, 2, 2]
            },
        'J1': {
                'x': [0, 1, 2, 2],
                'y': [0, 0, 0, 1]
            },
        'J2': {
                'x': [0, 1, 1, 1],
                'y': [0, 0, 1, 2]
            },
        'J3': {
                'x': [0, 0, 1, 2],
                'y': [0, 1, 1, 1]
            },
        'T0': {
                'x': [0, 1, 2, 1],
                'y': [0, 0, 0, 1]
            },
        'T1': {
                'x': [0, 0, 1, 0],
                'y': [0, 1, 1, 2]
            },
        'T2': {
                'x': [0, -1, 0, 1],
                'y': [0, 1, 1, 1]
            },
        'T3': {
                'x': [0, -1, 0, 0],
                'y': [0, 1, 1, 2]
            },
        
        }

    // Returns an array of all cell indicies that would be affected 
    // by the user clicking on the given square index
    // according to that square's command shape 
    const affectedSquares = (squareId) => {
        let piece = board[squareId]
        let origin = piece.slice(-1) 

        let commandShape = commandShapes[piece.slice(0,2)]
        let xOffset = commandShape.x[origin]
        let yOffset = commandShape.y[origin]

        let addrsX = commandShape.x
        let addrsY = commandShape.y

        return addrsX.map((x, idx) => squareId + x + addrsY[idx] * 4 - xOffset - yOffset * 4);
    }

    const handleSquareClick = (squareId) => {
        // Using affectedSquares to get the indicies of affected squares
        // toggle each affected square
        const affected = affectedSquares(squareId)
        setSquareStates(prev => prev.map((cell,idx) => affected.includes(idx) ? !cell : cell))
        
        // Up the number of clicks by one 
        setClicks(prev => prev + 1)
        setPrevSquare(prev => [squareId, ...prev])
    }

    const undo = () => {
        if (prevSquare.length > 0){
            const affected = affectedSquares(prevSquare.pop())
            setSquareStates(prev => prev.map((cell,idx) => affected.includes(idx) ? !cell : cell))
        }
    }

    const resetBoard = () => {
        setSquareStates(initialBoard)
        setPrevSquare([])
    }

    const newGame = () => {
        setBoard(generateBoard())
        setPrevSquare([])
    }

    /// Check for win
    useEffect(()=>{
        // Sum up the number of squares for which isOn is true
        if (clicks > 0) { 
            const ons = squareStates.reduce((acc,sq) => (sq ? acc + 1 : acc))
            // If that number equals the number of squares, the game is won
            if (ons === squareStates.length) {
                console.log("Game is won!")
                setHasWon(true)
            }
        }
    },[clicks,squareStates])

    // Win effects
    useEffect(() => {
        if (clicks > 0 & hasWon) { 
            alert(`GAME OVAR: ${clicks} CLICKS`)
        }
    },[clicks,hasWon])

    useEffect(() => {
        // This effect will run only once after the initial render
        setBoard(generateBoard());
      }, []); // Empty dependency array means it runs only once after initial render
      
      useEffect(() => {
        if (board.length > 0) {
          // Generate the initial board state and run monkeyMix
          const initialBoardState = monkeyMix();
      
          // Apply monkeyMix directly to update the board
          setSquareStates(prevSquareStates => {
            let updatedSquareStates = [...prevSquareStates];
      
            for (let sqid = 0; sqid < initialBoardState.length; sqid++) {
              if (initialBoardState[sqid]) {
                // Toggle the affected squares
                for (const cell of affectedSquares(sqid)) {
                  updatedSquareStates[cell] = !updatedSquareStates[cell];
                }
              }
            }
      
            // Set the initial board state
            setInitialBoard(updatedSquareStates);
      
            return updatedSquareStates;
          });
        }
      }, [board]);
            
    return (  
        <div className='container'>
            <Title />
            <div className='button-container-top'>
                <div className='secondary-button' onClick={resetBoard}>Reset Game</div>
                <div className='secondary-button' onClick={newGame}>New Game</div>
            </div>
            <div className='game-container'>
            <div className='grid-container'>
                {squareStates.map((isOn,index) => (
                    <Square 
                        key={index} 
                        isOn={isOn}
                        onClick={() => handleSquareClick(index)}
                        gridState={squareStates}
                        affectedAddrs={affectedSquares(index)}
                        affectedOns={affectedSquares(index).map(idx => squareStates[idx])}
                        origin={board[index].slice(-1) }
                    />
                ))}
            </div>
        </div>
        <div className={('primary-button undo ' + (prevSquare.length > 0 ? 'active' : 'inactive'))} onClick={undo}>Undo</div>
    </div>
  );
}

